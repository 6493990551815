<template>
    <div class="page-star-coach iphonex-pb">
        <HeadeBar left-arrow @click-left="newAppBack" :placeholder="false" onlyBack color="#fff" class="rback">
        </HeadeBar>
        <div class="tbg"></div>
        <div class="filterbar">
            <div class="search-box" :class="{ long: searchName ? true : false }">
                <img alt="" src="https://img.chaolu.com.cn/ACT/sell-schedule-2024/search.png" class="search-icon" />
                <form action="JavaScript：return true;" @submit.prevent="searchData" class="sform">
                    <input type="search" v-model.trim="searchName" @input="searchData" class="ipt"
                        placeholder="输入教练的名字" />
                </form>
                <img alt="" src="https://img.chaolu.com.cn/ACT/sell-schedule-2024/close.png" class="search-clear"
                    @click="clear" v-show="searchName ? true : false" />
            </div>
            <div class="citybox" @click.self="show = true">
                {{ getCityName() }}
                <img alt="" src="https://img.chaolu.com.cn/ACT/sell-schedule-2024/down.png" class="search-down" />
                <transition name="van-fade">
                    <div class="citypop" v-show="show">
                        <div @click="setCity(item)" class="cpm" :class="{ active: item.cityId === currentCityId }"
                            v-for="item in cityList">{{ item.areaName }}</div>
                    </div>
                </transition>
                <van-overlay :show="show" @click="show = false" />
            </div>
        </div>
        <div class="con">
            <template v-if="hasData">
                <div class="coach-model" v-for="item in dataList"
                    :style="{ backgroundImage: 'url(https://img.chaolu.com.cn/ACT/sell-schedule-2024/coach/' + item.url + '.png)' }">
                    <!-- <div class="c-head">
                        <img alt="" :src="$getImgUrl(item.pic)" />
                    </div>
                    <div class="c-name">{{ item.teacherName }}</div>
                    <div class="c-course">
                        <div class="c-cl">48元团课</div>
                        <div class="c-cl">48元团课</div>
                        <div class="c-cl">48元团课</div>
                    </div>
                    <div class="c-free">免费权益</div>
                    <div class="c-price">
                        +¥
                        <span class="value">{{ item.starTeacherPriceDifference }}</span>
                    </div> -->
                </div>
            </template>
            <div v-else class="null">
                <img alt="" src="https://img.chaolu.com.cn/ACT/sell-schedule-2024/null.png" class="null-img" />
                <div class="null-text">暂无数据</div>
            </div>
        </div>
    </div>
</template>

<script>
import { newAppBack, initBack, showGroupQuickDialog } from '@/lib/appMethod';
import HeadeBar from '@/components/app/headBar';
import userMixin from '@/mixin/userMixin';
import wx from 'weixin-js-sdk'
import PopCourse from '@/components/app/popCourse.vue';
import { hasData } from 'jquery';
const cdata = [
    { name: 'Ken', url: 'fz-Ken', cityId: '3' },
    { name: 'APPLE', url: 'fz-APPLE', cityId: '3' },
    { name: '颜力', url: 'fz-颜力', cityId: '3' },
    { name: '屠逸然', url: 'fz-屠逸然', cityId: '3' },
    { name: '香香Uki', url: 'fz-香香Uki', cityId: '3' },
    { name: '大裕老师', url: 'fz-大裕老师', cityId: '3' },
    { name: '吴田雨', url: 'fz-吴田雨', cityId: '3' },
    { name: '范剑炜', url: 'fz-范剑炜', cityId: '3' },
    { name: '李诗兰', url: 'fz-李诗兰', cityId: '3' },
    { name: '张虹', url: 'fz-张虹', cityId: '3' },
    { name: '丁丁', url: 'fz-丁丁', cityId: '3' },
    { name: 'Stephen-小鱼', url: 'fz-Stephen-小鱼', cityId: '3' },
    { name: 'CK', url: 'fz-CK', cityId: '3' },
    { name: 'KIVA', url: 'xm-KIVA', cityId: '036652995ac5451fbb6791c3491e1c2d' },
    { name: 'Win', url: 'xm-Win', cityId: '036652995ac5451fbb6791c3491e1c2d' },
    { name: '李广富', url: 'xm-李广富', cityId: '036652995ac5451fbb6791c3491e1c2d' },
    { name: '林海', url: 'xm-林海', cityId: '036652995ac5451fbb6791c3491e1c2d' },
    { name: 'Maggie', url: 'gz-Maggie', cityId: '818447834079563776' },
    { name: '王章儒', url: 'gz-王章儒', cityId: '818447834079563776' },
    { name: '李二一', url: 'gz-李二一', cityId: '818447834079563776' },
    { name: '曾嘉欣', url: 'gz-曾嘉欣', cityId: '818447834079563776' },
    { name: 'Joan', url: 'gz-Joan', cityId: '818447834079563776' },
]
export default {
    mixins: [userMixin],
    components: {
        HeadeBar,
        PopCourse
    },
    data() {
        return {
            searchName: '',
            dataList: [],
            cityList: [],
            hasData: true,
            show: false,
            currentCityId: undefined
        }
    },
    methods: {
        newAppBack,
        getCityName() {
            return (this.cityList.find(d => {
                return d.cityId === this.currentCityId
            }) || { areaName: '福州市' }).areaName
        },
        setCity(item) {
            this.currentCityId = item.cityId;
            this.show = false;
            this.getData();
        },
        clear() {
            this.searchName = '';
            this.getData();
        },
        searchData() {
            clearTimeout(this.timer)
            this.timer = setTimeout(() => {
                this.getData()
            }, 200)
        },
        async getData() {
            const ps = {
                teacherName: this.searchName,
                cityId: this.currentCityId
            }
            if (ps.teacherName) {
                ps.cityId = undefined;
            }
            // const res = await this.$axios.post(this.baseURLApp + "/c/teacher/course/startTeacher/list", ps)
            const res = {
                data: cdata.filter(d => {
                    let ok = true;
                    if (ps.cityId) {
                        ok = d.cityId == ps.cityId
                    }
                    if (ps.teacherName) {
                        ok = d.name.toUpperCase().includes(ps.teacherName.toUpperCase())
                    }
                    return ok
                })
            }
            this.dataList = res.data;
            this.hasData = res.data.length > 0


        },
        getCityList() {
            this.$axios.post(`${this.baseURLApp}/scheduleLesson/cityList`, { userId: this.userId }).then(res => {
                this.cityList = res.data
                let item = this.cityList.find(r => { return r.cityId === this.currentCityId })
                if (item) {
                    this.cityName = item.areaName
                    this.currentCityId = item.cityId
                }
            })
        },
    },
    mounted() {

    },
    async created() {
        initBack();
        await this.$getAllInfo(['userId', 'cityId']);
        this.currentCityId = this.cityId;
        this.getCityList();
        this.getData();
    }
}
</script>

<style lang="less" scoped>
.page-star-coach {
    min-height: 100vh;
    background: #F9F9F9;
    // position: fixed;
    ////height: 100vh;
    // top: 0;
    // bottom: 0;
    // left: 0;
    // right: 0;
    // overflow: auto;
    // -webkit-overflow-scrolling: touch;

    div {
        box-sizing: border-box;
    }

    img {
        vertical-align: top;
        width: 100%;
    }
    .tbg{
        height: 388px;
        background: url(https://img.chaolu.com.cn/ACT/sell-schedule-2024/starbg.png?bb);
        background-size: 100% 100%;
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
    }
    .filterbar {
        position: fixed;
        top: 0;
        right: 0;
        left: 0;
        height: 388px;
        display: flex;
        padding: 232px 16px 0;
  
        z-index: 11;

        .search-box {
            position: absolute;
            z-index: 1;
            border-radius: 8px;
            background: #fff;
            height: 64px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            padding: 0 16px 0 24px;
            margin: 0 20px 0 0;
            width: 324px;
            transition: width .5s;

            input[type="search"] {
                -webkit-appearance: none;
            }

            input::-webkit-search-cancel-button {
                display: none;
            }

            input::-webkit-input-placeholder {
                /* WebKit browsers */

                color: #6C727A;

            }

            .search-icon {
                width: 32px;
                height: 32px;
                margin-right: 8px;
            }

            .sform {
                flex: 1;
                height: 28px;
            }

            .ipt {
                border: none;
                background: transparent;
                font-size: 24px;
                width: 100%;
                height: 100%;
                vertical-align: top;
            }

            .search-clear {
                width: 24px;
                height: 24px;
            }
        }

        .long {
            width: 490px;
        }

        .citybox {
            margin-left: 344px;
            height: 64px;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            padding: 0 24px;
            display: flex;
            align-items: center;
            font-weight: 500;
            font-size: 24px;
            color: #000000;
            width: fit-content;
            position: relative;

            .search-down {
                margin-left: 8px;
                width: 16px;
                height: 16px;
            }

            .citypop {
                position: absolute;
                z-index: 50;
                left: 0;
                top: 100%;
                width: 264px;
                background: #FFFFFF;
                border-radius: 12px 12px 12px 12px;

                .cpm {
                    line-height: 96px;
                    padding: 0 32px;
                    font-size: 28px;
                    color: #3C454E;
                    position: relative;

                    &.active {
                        font-weight: 500;
                        color: #000000;

                        &::after {
                            content: '';
                            position: absolute;
                            right: 24px;
                            background: url(https://img.chaolu.com.cn/ACT/sell-schedule-2024/yes2.png);
                            background-size: 100% 100%;
                            width: 32px;
                            height: 32px;
                            top: 50%;
                            margin-top: -16px;

                        }
                    }
                }
            }
        }

    }

    .con {
        padding: 0 16px 0;
        position: relative;
        position: fixed;
        top: 312px;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: auto;


        .coach-model {
            height: 160px;
            background: #FFFFFF;
            border-radius: 8px 8px 8px 8px;
            margin-bottom: 16px;
            display: flex;
            align-items: center;
            padding: 0 28px;
            background-size: 100% 100%;

            .c-head {
                width: 96px;
                height: 96px;
                overflow: hidden;
                border-radius: 50%;

                img {
                    height: 100%;
                }
            }

            .c-name {
                font-weight: 600;
                font-size: 28px;
                color: #000000;
                margin: 0 auto 0 24px;
            }

            .c-course {
                margin: 0 auto;

                .c-cl {
                    font-size: 24px;
                    color: #000000;
                    margin-bottom: 14px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }

            .c-free {
                width: 108px;
                height: 44px;
                line-height: 44px;
                background: #FFF6E1;
                border-radius: 8px 8px 8px 8px;
                font-weight: 600;
                font-size: 22px;
                color: #A85500;
                text-align: center;
            }

            .c-price {
                font-weight: bold;
                font-size: 30px;
                color: #F03C18;
                margin-left: 4px;
            }
        }

        .null {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .null-img {
                width: 240px;
                height: 240px;
            }

            .null-text {
                font-size: 26px;
                color: #000000;
                margin-top: 44px;
                text-align: center;
            }
        }
    }

}
</style>